import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import formatListValues from '../utils/formatListValues';
import { BioScore } from '../types';

const ReachOutNow = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

type ComparisonResult = {
  betterThanBenchmarkCategories: string[];
  worseThanBenchmarkCategories: string[];
};

const BETTER_FOR_PEOPLE_AND_PLANET_CATEGORIES = [
  {
    betterTranslationString: 'resultsPage.biologicalIngredientsChoice',
    worseTranslationString: 'resultsPage.improveBiologicalIngredientsChoice',
    comparisonProperty: 'ingredientScore',
  },
  {
    betterTranslationString: 'resultsPage.efficiency',
    worseTranslationString: 'resultsPage.improveEfficiency',
    comparisonProperty: 'efficiencyScore',
  },
  {
    betterTranslationString: 'resultsPage.gentleNessToPlanet',
    worseTranslationString: 'resultsPage.improveGentleNessToPlanet',
    comparisonProperty: 'toxicityScore',
  },
];

type ComparisonResultProps = {
  resultScore: BioScore;
  benchMark: BioScore;
};
const ComparisonResult: FC<ComparisonResultProps> = ({ resultScore, benchMark }) => {
  const { t } = useTranslation();

  const peopleAndPlanetResults = useMemo(
    () =>
      BETTER_FOR_PEOPLE_AND_PLANET_CATEGORIES.reduce(
        (comparisonResult: ComparisonResult, category) => {
          const { comparisonProperty, betterTranslationString, worseTranslationString } = category;
          const resScore = resultScore[comparisonProperty as keyof BioScore];
          const benchmarkScore = benchMark && benchMark[comparisonProperty as keyof BioScore];
          if (typeof resScore === 'number' && typeof benchmarkScore === 'number' && resScore >= 4) {
            const translatedString = t(betterTranslationString);
            comparisonResult.betterThanBenchmarkCategories.push(translatedString);
          } else {
            const translatedString = t(worseTranslationString);
            comparisonResult.worseThanBenchmarkCategories.push(translatedString);
          }
          return comparisonResult;
        },
        {
          betterThanBenchmarkCategories: [],
          worseThanBenchmarkCategories: [],
        }
      ),
    [resultScore, benchMark]
  );

  const cleaningAndCostResults = useMemo(() => {
    const { cleaningScore, costScore } = resultScore;
    if (cleaningScore > benchMark.cleaningScore && costScore > benchMark.costScore) {
      return t('resultsPage.higherCleanAndCost');
    }
    if (cleaningScore === benchMark.cleaningScore && costScore === benchMark.costScore) {
      return t('resultsPage.equalCleanAndCost');
    }
    if (cleaningScore < benchMark.cleaningScore && costScore < benchMark.costScore) {
      return t('resultsPage.lowerCleanAndCost');
    }
    let cleaningString = '';
    if (cleaningScore > benchMark.cleaningScore) {
      cleaningString = t('resultsPage.higherClean');
    } else if (cleaningScore === benchMark.cleaningScore) {
      cleaningString = t('resultsPage.equalClean');
    } else if (costScore > benchMark.costScore) {
      cleaningString = t('resultsPage.lowerClean');
    } else {
      cleaningString = t('resultsPage.lowerClean2');
    }
    let costString = '';
    if (costScore > benchMark.costScore) {
      let fillWord = '';
      if (cleaningScore > benchMark.cleaningScore) {
        fillWord = t('common.but');
      } else if (cleaningScore === benchMark.cleaningScore) {
        fillWord = t('resultsPage.andScores');
      } else {
        fillWord = t('common.but');
      }
      costString = t('resultsPage.higherCost', {
        fillWord,
      });
    } else if (costScore === benchMark.costScore) {
      costString = t('resultsPage.equalCost', {
        fillWord: cleaningScore > benchMark.cleaningScore ? t('common.and') : t('common.but'),
      });
    } else {
      let fillWord = '';
      if (cleaningScore > benchMark.cleaningScore) {
        fillWord = t('common.but');
      } else if (cleaningScore === benchMark.cleaningScore) {
        fillWord = t('resultsPage.butScores');
      } else {
        fillWord = t('common.and');
      }
      costString = t('resultsPage.lowerCost', {
        fillWord,
      });
    }
    return `${cleaningString} ${costString}`;
  }, [resultScore, benchMark]);

  return (
    <>
      <Typography variant="h1">{t('resultsPage.howYourDetergentCompares')}</Typography>
      <ReachOutNow>
        <Typography variant="body1">
          {peopleAndPlanetResults.betterThanBenchmarkCategories.length > 0 && (
            <>
              {t('resultsPage.yourProductDoesGreatIn', {
                greatCategories: formatListValues(peopleAndPlanetResults.betterThanBenchmarkCategories, t),
              })}
              <br />
              <br />
            </>
          )}
        </Typography>
        <Typography variant="body1">
          {peopleAndPlanetResults.worseThanBenchmarkCategories.length > 0 && (
            <>
              {t('resultsPage.roomForImprovement', {
                improvementCategories: formatListValues(peopleAndPlanetResults.worseThanBenchmarkCategories, t),
              })}
              <br />
              <br />
            </>
          )}
        </Typography>
        <Typography variant="body1">
          <>
            {t('resultsPage.comparedToBenchmark', {
              format: t(`resultsPage.${resultScore.format}`),
              country: resultScore.country,
              result: cleaningAndCostResults,
            })}
            <br />
            <br />
          </>
        </Typography>
        <Typography variant="body1">{t('resultsPage.reachOutNow')}</Typography>
      </ReachOutNow>
    </>
  );
};

export default ComparisonResult;
