import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import { ButtonPrimary, ButtonSecondary } from '@novozymes/components';
import ClearFormModal from './ClearFormModal';
import InformationModal, { InformationModalContent } from './InformationModal';
import FormSelect from './FormSelect';
import getFormQuestionsByDetergentFormat from '../utils/getFormQuestionsByDetergentFormat';
import getInformationTextByDetergentFormatAndFieldName from '../utils/getInformationTextByDetergentFormatAndFieldName';
import { FormValues, FieldName, DetergentFormat } from '../types';
import { NOVOZYMES_GREEN } from '../colors';

const FormButtonContainer = styled.div`
  margin-top: 20px;
`;

const QuestionHeader = styled.div`
  display: flex;
`;

const ErrorText = styled.p`
  color: #bc0000;
  margin-top: 0;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 1rem;
`;

const RadioInput = styled.input`
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
`;

const QuestionHeaderText = styled.h2<{ hasError: boolean }>`
  ${({ hasError }): string => (hasError ? 'color: red' : '')}
`;

type DetergentFormProps = {
  format?: DetergentFormat;
};

const DetergentForm: FC<DetergentFormProps> = ({ format }) => {
  const { values, errors, touched, setFieldValue, resetForm, submitForm } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const [informationModalContent, setInformationModalContent] = useState<InformationModalContent | null>();
  const [shouldDisplayClearFormModal, setShouldDisplayClearFormModal] = useState(false);
  const questions = useMemo(() => getFormQuestionsByDetergentFormat(format, t), [format, t]);

  return (
    <Form>
      {informationModalContent && (
        <InformationModal {...informationModalContent} onClose={(): void => setInformationModalContent(null)} />
      )}
      {shouldDisplayClearFormModal && (
        <ClearFormModal onClose={(): void => setShouldDisplayClearFormModal(false)} onReset={(): void => resetForm()} />
      )}
      {questions.map((question) => {
        return (
          <div id={question.fieldName} key={question.question}>
            <QuestionHeader>
              <QuestionHeaderText
                hasError={!!errors[question.fieldName as keyof FormValues] && !!touched[question.fieldName as keyof FormValues]}
              >
                {question.question}
              </QuestionHeaderText>
              <InfoIcon
                type="button"
                style={{ color: NOVOZYMES_GREEN, cursor: 'pointer' }}
                onClick={(): void =>
                  setInformationModalContent({
                    headerText: question.question,
                    bodyText: getInformationTextByDetergentFormatAndFieldName(format, question.fieldName as FieldName, t),
                  })
                }
              />
            </QuestionHeader>
            <ErrorMessage name={question.fieldName} component={ErrorText} />
            {question.fieldType === 'select' ? (
              <Field name={question.fieldName} component={FormSelect} options={question.options} ariaLabel={question.question} />
            ) : (
              <div>
                {question.options.map((option) => (
                  <RadioContainer key={option.value}>
                    <RadioInput
                      type="radio"
                      id={`${question.fieldName}-${option.label}`}
                      name={question.question}
                      value={option.value}
                      checked={values[question.fieldName as FieldName] === option.value}
                      onChange={(event): void => setFieldValue(question.fieldName, parseInt(event.target.value, 10))}
                    />
                    <Label htmlFor={`${question.fieldName}-${option.label}`}>{option.label}</Label>
                    <br />
                  </RadioContainer>
                ))}
              </div>
            )}
          </div>
        );
      })}
      <FormButtonContainer>
        <ButtonPrimary id="calculate-button" size="small" onClick={(): Promise<void> => submitForm()}>
          {t('common.calculate')}
        </ButtonPrimary>
        <ButtonSecondary id="clear-button" size="small" onClick={(): void => setShouldDisplayClearFormModal(true)}>
          {t('formPage.clear')}
        </ButtonSecondary>
      </FormButtonContainer>
    </Form>
  );
};

export default DetergentForm;
