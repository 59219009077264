export const DETERGENT_FORMAT = {
  LIQUID: 'liquid',
  POWDER: 'powder',
  LIQUID_UNIT_DOSE: 'unitDose',
};

export type FieldName =
  | 'overallBio'
  | 'cmr'
  | 'recommendedDosage'
  | 'lowestRecommendedWashTemp'
  | 'bioDegradability'
  | 'cleaningExperience'
  | 'detergentCost';

export type DetergentFormat = 'liquid' | 'powder' | 'unitDose';

export type Score = {
  ingredientScore: number;
  efficiencyScore: number;
  toxicityScore: number;
  cleaningScore: number;
  costScore: number;
};
export type BioScore = Score & {
  country: string;
  format: DetergentFormat;
};

export type FormValues = {
  country: string;
  format: DetergentFormat;
  overallBio: number;
  cmr: number;
  recommendedDosage: number;
  lowestRecommendedWashTemp: number;
  bioDegradability: number;
  cleaningExperience: number;
  detergentCost: number;
};

export type ScoreByFormat = {
  [format in DetergentFormat]: BioScore;
};

export type BenchMarksByCountry = {
  [country: string]: ScoreByFormat;
};
