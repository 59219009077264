import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { ButtonSecondary } from '@novozymes/components';
import Modal from './Modal';

const CalculatedScore = styled.div`
  padding: 20px;
`;
const DisplayScoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ReachOutNow = styled.a`
  cursor: pointer;
`;

type HowWeCalculatedScoreModalProps = {
  onClose: () => void;
  showContactFormModal: () => void;
};

const HowWeCalculatedScoreModal: FC<HowWeCalculatedScoreModalProps> = ({ onClose, showContactFormModal }) => {
  const { t } = useTranslation();
  return (
    <Modal id="how-we-calculated-result-modal" onClose={onClose}>
      <CalculatedScore>
        <Typography variant="h1">{t('resultsPage.howWeCalculatedScore')}</Typography>
        <Typography variant="subtitle1">{t('resultsPage.howWeCalculatedScoreInfo')}</Typography>
        <br />
        <ReachOutNow>
          <Typography
            variant="subtitle1"
            onClick={(): void => {
              onClose();
              showContactFormModal();
            }}
          >
            {t('resultsPage.reachOutNow')}
          </Typography>
        </ReachOutNow>
        <DisplayScoreButtonContainer>
          <ButtonSecondary id="how-calculate-close-button" size="small" onClick={onClose}>
            {t('common.close')}
          </ButtonSecondary>
        </DisplayScoreButtonContainer>
      </CalculatedScore>
    </Modal>
  );
};

export default HowWeCalculatedScoreModal;
