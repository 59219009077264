/* eslint-disable max-len */
import countries from './countries';

export default {
  translation: {
    countries,
    common: {
      and: 'and',
      but: ', but',
      calculate: 'Calculate',
      contactUs: 'Contact us',
      yes: 'Yes',
      no: 'No',
      previousPage: 'Previous page',
      close: 'Close',
    },
    contactModal: {
      text: "Fill out the fields below and we'll get in touch.",
      thankYouForContacting: 'Thank you for reaching out. We will be in touch shortly.',
      yesIWouldLikeToReceiveUpdates:
        "Yes, I'd like to receive occasional updates about industry news, products and samples, per e-mail, SMS and/or social media",
      IAgreeWithNovozymes: "I agree with Novozymes'",
      termsAndConditions: 'terms and conditions',
      privacyPolicy: 'privacy policy.',
      andAcknowledgeNovozymes: "and acknowledge Novozymes'",
    },
    formPage: {
      formHeader: 'How green/eco-friendly is your detergent compared to the others in the market?',
      introduction:
        "With this simple guide, you'll be able to estimate how your detergent performs compared to other brands in the market, when it comes to the sustainability profile and the performance and its cost.",
      country: 'In which country do you market/sell your brand?',
      detergentFormat: "What's the detergent's format?",
      contactUsBody:
        'Unfortunately, we don’t have sufficient data to conduct a benchmark for detergents in this market. You are more than welcome to get in touch to discuss your formulation opportunities.',
      noBenchmarksForCountry:
        'Unfortunately, we do not have any benchmarks for your market. Get in touch now to discover your formulation opportunities.',
      clear: 'Clear',
      liquid: 'Liquid',
      powder: 'Powder',
      unitDose: 'Liquid unit dose',
      clearModal: {
        header: 'Clear form?',
        text: 'This will remove all the form values that you have filled in. Are you sure you want to clear?',
      },
      informationText: {
        liquidOverallBio1: 'Excluding water and mineral ingredients (such as NaCl).',
        liquidOverallBio2: 'LAS is 0% biobased. If LAS is part of your formulation, it will likely be < 50% biobased overall.',
        liquidOverallBio3: 'AEOs (such as Laureth-7) are around 50% biobased.',
        liquidCMR:
          'Examples for ingredients classified as CMR: BIT/MIT/CMIT, DEA (Diethanolamine), Sodium perborate and most borates.',
        liquidRecommendedDosage1: 'How much detergent is recommended for a standard load of laundry?',
        liquidRecommendedDosage2:
          'More compact detergent formulations need less energy for transport and storage and have therefore a better eco profile.',
        lowestRecommendedWashTemp: 'As stated on the package.',
        liquidBioDegradability1: 'LAS, CMC, MIT/BIT and phenoxyethanol are maximal inherently biodegradable.',
        liquidBioDegradability2: 'Opacifiers and most polyacrylates are non-biodegradable.',
        liquidBioDegradability3: 'SLES, SLS, MEA are readily biodegradable.',
        powderOverallBio1: 'Excluding mineral ingredients (such as sodium carbonate or zeolites).',
        powderOverallBio2: 'LAS is 0% biobased. If LAS is part of your formulation, it will likely be < 50% biobased overall.',
        powderOverallBio3: 'AEOs (such as Laureth-7) are around 50% of bio based origin.',
        powderCMR: 'Examples for ingredients classified as CMR: Sodium perborate and most borates.',
        powderRecommendedDosage1: 'How much detergent is recommended for a standard load of laundry?',
        powderRecommendedDosage2:
          'More compact detergent formulations need less energy for transport and storage and have therefore a better eco profile.',
        powderDegradability1: 'LAS and CMC are maximal inherently biodegradable.',
        powderDegradability2: 'Most polyacrylates are non-biodegradable.',
        powderDegradability3: 'AEOs (such as Laureth-7) are readily biodegradable.',
        unitDoseOverallBio1: 'Excluding PVA film, water and mineral ingredients (such as NaCl).',
        unitDoseOverallBio2: 'AEOs (such as Laureth-7) are around 50% biobased.',
        unitDoseOverallBio3: 'Propylene glycol (MPG, DPG) is 0% biobased.',
        unitDoseOverallBio4: 'Glycerol is 100% biobased.',
        unitDoseCMR: 'Examples for ingredients classified as CMR: BIT/MIT/CMIT, Some Polyethylenimine-EO.',
        unitDoseRecommendedDosage1: 'This is to assess the amount of detergent used for a standard wash load.',
        unitDoseRecommendedDosage2:
          'If you recommend to use more than one unit dose capsule for a standard wash load, then multiply the amount of detergent in one capsule with the number of recommended capsules.',
        unitDoseDegradability1: 'MIT/BIT and phenoxyethanol are maximal inherently biodegradable.',
        unitDoseDegradability2: 'AEOs (such as Laureth-7) and SLES are readily biodegradable.',
        unitDoseDegradability3: 'Many polymers are not biodegradable.',
        cleaningExperience1: 'Compared to similar products in your market.',
        cleaningExperience2: 'Can be in house data or based on consumer test results.',
        detergentCost: 'Formulation cost in relation to the detergent’s performance.',
      },
      overallBio: {
        question: 'How bio based is your formulation overall?',
        lessThan50: 'Less than 50%',
        '50to95': '50% - 95%',
        '96to99': '96% - 99%',
        '100': '100%',
      },
      cmr: {
        question: 'Does your formulation contain any ingredients classified as carcinogenic, metagenic, or reprotoxic (CMR)?',
        yes: 'Yes',
        no: 'No',
      },
      recommendedDosage: {
        question: 'What is the recommended dosage of your detergent?',
        liquid: {
          moreThan55mL: 'More than 55 mL',
          '55mL': '55 mL',
          '25to54mL': '25-54 mL',
          lessThan25mL: 'Less than 25 mL',
        },
        powder: {
          moreThan75g: 'More than 75g',
          '45to75g': '45 - 75g',
          '31to45g': '31 - 45g',
          lessThan31g: 'Less than 31g',
        },
        liquidUnitDose: {
          moreThan25mL: 'More than 25 mL',
          '21to25mL': '21 - 25 mL',
          '15to20mL': '15 - 20 mL',
          lessThan15mL: 'Less than 15 mL',
        },
      },
      lowestRecommendedWashTemp: {
        question: 'What is the lowest recommended wash temperature for your detergent?',
        '40degCelsius': '40\u00B0C (104\u00B0F)',
        '30degCelsius': '30\u00B0C (86\u00B0F)',
        '20degCelsius': '20\u00B0C (68\u00B0F)',
        coldWater: 'Cold water (ambient temperature)',
      },
      bioDegradability: {
        question: 'What is the biodegradability profile of the ingredients in your detergent?',
        manyNonBioDegradable: 'Many non-biodegradable ingredients used',
        fewNonBioDegradable: 'Few non-biodegradable ingredients used',
        mostBioDegradableAerobically: 'Most ingredients are readily biodegradable (aerobically)',
        mostBioDegradableAerobicallyAndAnaerobically: 'Most ingredients are readily biodegradable, aerobically and anaerobically',
        allIngredientsBiodegradable: 'All ingredients are readily biodegradable, aerobically and anaerobically',
      },
      cleaningExperience: {
        question: "How do you rate your detergent's cleaning experience in your market?",
        belowAverage: 'Below average',
        average: 'Average',
        aboveAverage: 'Above Average',
        outStanding: 'Outstanding',
      },
      detergentCost: {
        question: 'How does the cost of your formulation compare to detergents with the same performance?',
        moreExpensive: 'More expensive',
        onPar: 'On par',
        lessExpensive: 'Less expensive',
      },
    },
    resultsPage: {
      howYourDetergentCompares: 'How your detergent formulation compares',
      howWeCalculatedScore: 'How we have calculated your score',
      howWeCalculatedScoreInfo:
        'This guide is based on simulations and data laid out by Novozymes. An in-depth analysis of your detergent formulation may lead to more accurate results.',
      reachOutNow: "Reach out to us and let's discuss how we can together optimize your formulation.",
      howeverThere: 'However, there',
      there: 'There',
      yourProductDoesGreatIn:
        'Well done, you are on the way towards building a 100% biological detergent. Your formulation gains high marks for {{greatCategories}}.',
      roomForImprovement:
        'Looking at the details, your detergent can be even better for people and planet if you {{improvementCategories}}.',
      biologicalIngredientsChoice: 'using more than 95% biological and harmless ingredients',
      efficiency: 'having a concentrated formulation and/or enabling usage at low wash temperatures',
      gentleNessToPlanet: 'using mostly biodegradable ingredients',
      improveBiologicalIngredientsChoice: 'use a greater percentage of biological and harmless ingredients',
      improveEfficiency: 'concentrate the formulation and/or enable usage at lower wash temperature',
      improveGentleNessToPlanet: 'use more biodegradable ingredients',
      liquid: 'liquid',
      powder: 'powder',
      unitDose: 'liquid unit dose',
      comparedToBenchmark: 'Compared to a conventional {{format}} detergent in {{country}}, {{result}}',
      higherCleanAndCost:
        'your detergent’s cleaning experience scores higher than the benchmark, also in terms of competitive cost.',
      equalCleanAndCost: 'your detergent’s cleaning experience and competitive cost is on par with the benchmark.',
      lowerCleanAndCost: 'your detergent’s cleaning experience and competitive cost is lower than the benchmark.',
      higherClean: 'your detergent’s cleaning experience scores higher than the benchmark',
      equalClean: 'your detergent’s cleaning experience is on par with the benchmark',
      lowerClean: 'your detergent’s cleaning experience scores lower than the benchmark',
      lowerClean2: 'your detergent’s cleaning experience is scored below the benchmark',
      butScores: ', but scores',
      andScores: 'and scores',
      higherCost: '{{fillWord}} higher in terms of competitive cost.',
      equalCost: '{{fillWord}} is on par in terms of competitive cost.',
      lowerCost: '{{fillWord}} lower in terms of competitive cost.',
      cleaningExperience: 'giving a good cleaning experience',
      competitiveFactor: 'being cost-competitive',
      goBackModal: {
        header: 'Go back?',
        text: 'Going back will reset the form. Are you sure you want to go back?',
      },
      engage: {
        header: 'Still not sure you should reformulate your detergent and make it even more bio-based?',
        subtitle:
          'Explore consumer trends, calculate your potential CO2 savings and find out how to enable greener and high performing detergents below.',
        greenerDetergents: 'How to enable greener and high performing detergents?',
        readWhitePaper: 'Read our white paper',
        howMuchCO2: 'How much CO2 could you be saving?',
        tryCalculator: 'Try the calculator',
        consumerTrends: 'Consumer trends impacting the industry',
        readTrendArticle: 'Read our trend article',
      },
      graph: {
        betterForThePeopleAndPlanet: 'Better for the people and planet',
        yourEstimatedDetergent: 'Your estimated detergent',
        typicalBioDetergentInYourCountry: 'A typical bio detergent in {{country}}',
        typicalDetergentInYourCountry: 'A typical detergent in {{country}}',
        biologicalIngredients: 'Biological ingredients',
        efficiency: 'Efficiency',
        gentleToPlanet: 'Gentle to planet',
        cleaningExperience: 'Cleaning experience',
        competitiveCost: 'Competitive cost',
        hundredPercentBio: '100% biological detergent',
      },
    },
  },
};
