import React, { FC } from 'react';
import Select from 'react-select';
import { useFormikContext, FieldProps } from 'formik';
import { DetergentFormat } from '../types';

type FormValues = {
  country: string;
  format: DetergentFormat;
  overallBio: number;
  cmr: number;
  recommendedDosage: number;
  lowestRecommendedWashTemp: number;
  bioDegradability: number;
  cleaningExperience: number;
  detergentCost: number;
};

type SelectOption = {
  value: number;
  label: string;
};

interface FormSelectProps extends FieldProps {
  options: SelectOption[];
  ariaLabel: string;
}

const FormSelect: FC<FormSelectProps> = (props) => {
  const { options, field, ariaLabel } = props;
  const { setFieldValue } = useFormikContext<FormValues>();
  return (
    <Select
      styles={{
        container: (provided): Record<string, any> => ({
          ...provided,
          fontSize: '16px',
        }),
        placeholder: (provided): Record<string, any> => ({
          ...provided,
          color: '#767676',
          opacity: 1,
        }),
      }}
      aria-label={ariaLabel}
      options={options}
      onChange={(option: any): void => {
        setFieldValue(field.name, option.value);
      }}
    />
  );
};

export default FormSelect;
