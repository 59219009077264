import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSecondary, ButtonPrimary } from '@novozymes/components';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BENCHMARKS_BY_COUNTRY from '../assets/benchmarksByCountry.json';
import { BioScore } from '../types';
import ResultGraph from './ResultGraph';
import MobileResultGraph from './MobileResultGraph';
import GoBackModal from './GoBackModal';
import ContactFormModal from './ContactFormModal';
import EngageComponent from './EngageComponent';
import HowWeCalculatedScore from './HowWeCalculatedScoreModal';
import ComparisonResult from './ComparisonResult';

type ResultsComponentProps = {
  resultScore: BioScore;
  onBackPress: () => void;
};

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-bottom: 20px;
`;

const ResultsPage: FC<ResultsComponentProps> = ({ resultScore, onBackPress }) => {
  const [shouldDisplayGoBackModal, setShouldDisplayGoBackModal] = useState(false);
  const [shouldDisplayCalculateScoreInfo, setShouldDisplayCalculateScoreInfo] = useState(false);
  const [shouldShowContactForm, setShouldShowContactForm] = useState<boolean>();
  const { t } = useTranslation();
  const benchMark = useMemo(() => {
    const { country, format } = resultScore;
    const benchmarksForCountry = BENCHMARKS_BY_COUNTRY[country as keyof typeof BENCHMARKS_BY_COUNTRY];
    return benchmarksForCountry[format as keyof typeof benchmarksForCountry] as BioScore;
  }, [resultScore]);

  return (
    <ResultsContainer>
      {shouldDisplayGoBackModal && <GoBackModal onReset={onBackPress} onClose={(): void => setShouldDisplayGoBackModal(false)} />}
      {shouldDisplayCalculateScoreInfo && (
        <HowWeCalculatedScore
          onClose={(): void => setShouldDisplayCalculateScoreInfo(false)}
          showContactFormModal={(): void => setShouldShowContactForm(true)}
        />
      )}
      {shouldShowContactForm && (
        <ContactFormModal
          country={resultScore.country}
          resultScore={resultScore}
          onClose={(): void => setShouldShowContactForm(false)}
        />
      )}
      <ButtonContainer>
        <ButtonSecondary id="result-page-previous-button" size="small" onClick={(): void => setShouldDisplayGoBackModal(true)}>
          <ChevronLeftIcon />
          {t('common.previousPage')}
        </ButtonSecondary>
      </ButtonContainer>
      <ComparisonResult benchMark={benchMark} resultScore={resultScore} />
      <ButtonPrimary size="small" onClick={(): void => setShouldShowContactForm(true)}>
        {t('common.contactUs')}
      </ButtonPrimary>
      {benchMark && resultScore && <ResultGraph benchMark={benchMark} resultScore={resultScore} />}
      {benchMark && resultScore && <MobileResultGraph benchMark={benchMark} resultScore={resultScore} />}
      <ButtonContainer>
        <ButtonSecondary
          id="result-page-how-calculated-button"
          size="small"
          onClick={(): void => setShouldDisplayCalculateScoreInfo(true)}
        >
          {t('resultsPage.howWeCalculatedScore')}
        </ButtonSecondary>
      </ButtonContainer>
      <EngageComponent />
    </ResultsContainer>
  );
};

export default ResultsPage;
