import React, { FC, useEffect, useState } from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BioScore, Score } from '../types';
import { NOVOZYMES_GREEN, NOVOZYMES_BLUE } from '../colors';

type MobileResultGraphProps = {
  resultScore: BioScore;
  benchMark: BioScore;
};

type RechartsData = {
  name: string;
  bench: number;
  result: number;
};

const COUNTRIES_WITH_CHANGED_LEGEND = ['Argentina', 'Brazil', 'Chile', 'Colombia'];

const ChartWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  width: 100%;
`;

const MobileResultGraph: FC<MobileResultGraphProps> = ({ resultScore, benchMark }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<RechartsData[]>();
  const BIOLOGICAL_INGREDIENTS = t('resultsPage.graph.biologicalIngredients');
  const EFFICIENCY = t('resultsPage.graph.efficiency');
  const GENTLE_TO_PLANET = t('resultsPage.graph.gentleToPlanet');
  const CLEANING_EXPERIENCE = t('resultsPage.graph.cleaningExperience');
  const COMPETITIVE_COST = t('resultsPage.graph.competitiveCost');
  const YOUR_ESTIMATED_DETERGENT = t('resultsPage.graph.yourEstimatedDetergent');
  const TYPICAL_DETERGENT_IN_YOUR_COUNTRY = COUNTRIES_WITH_CHANGED_LEGEND.includes(resultScore.country)
    ? t('resultsPage.graph.typicalBioDetergentInYourCountry', { country: resultScore.country })
    : t('resultsPage.graph.typicalDetergentInYourCountry', { country: resultScore.country });

  const CATEGORIES = [
    {
      name: BIOLOGICAL_INGREDIENTS,
      comparisonProperty: 'ingredientScore',
    },
    { name: EFFICIENCY, comparisonProperty: 'efficiencyScore' },
    { name: GENTLE_TO_PLANET, comparisonProperty: 'toxicityScore' },
    { name: CLEANING_EXPERIENCE, comparisonProperty: 'cleaningScore' },
    { name: COMPETITIVE_COST, comparisonProperty: 'costScore' },
  ];

  useEffect(() => {
    if (resultScore && benchMark) {
      setData(
        CATEGORIES.map(({ name, comparisonProperty }) => ({
          name,
          bench: benchMark[comparisonProperty as keyof Score],
          result: resultScore[comparisonProperty as keyof Score],
        }))
      );
    }
  }, [benchMark, resultScore]);

  const renderTick = (props: any): JSX.Element | null => {
    const { x, y, payload } = props;
    return (
      <g fontSize="12px" fill="#000">
        <text x={x + 12} y={y - 10} textAnchor="top">
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ChartWrapper id="mobile-result-graph">
      <ResponsiveContainer width="99%" aspect={0.7}>
        <BarChart data={data} layout="vertical">
          <Bar name={YOUR_ESTIMATED_DETERGENT} dataKey="result" fill={NOVOZYMES_GREEN} />
          <Bar name={TYPICAL_DETERGENT_IN_YOUR_COUNTRY} dataKey="bench" fill={NOVOZYMES_BLUE} />
          <XAxis
            width={0}
            type="number"
            interval="preserveStart"
            ticks={[1, 2, 3, 4, 5]}
            scale="linear"
            domain={[0, 'dataMax']}
          />
          <YAxis width={1} type="category" orientation="left" dataKey="name" tickLine={false} tick={renderTick} />
          <Legend verticalAlign="top" wrapperStyle={{ fontSize: 14 }} />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default MobileResultGraph;
