import { TFunction } from 'i18next';

const formatListValues = (list: string[], t: TFunction): string => {
  if (list.length === 0) {
    return '';
  }
  if (list.length === 1) {
    return list[0];
  }
  const last = list.pop();
  return `${list.join(', ')} ${t('common.and')} ${last}`;
};

export default formatListValues;
