import { TFunction } from 'i18next';
import { DetergentFormat, FieldName } from '../types';

const translationKeysByFormatAndFieldName = {
  liquid: {
    overallBio: ['liquidOverallBio1', 'liquidOverallBio2', 'liquidOverallBio3'],
    cmr: ['liquidCMR'],
    recommendedDosage: ['liquidRecommendedDosage1', 'liquidRecommendedDosage2'],
    lowestRecommendedWashTemp: ['lowestRecommendedWashTemp'],
    bioDegradability: ['liquidBioDegradability1', 'liquidBioDegradability2', 'liquidBioDegradability3'],
    cleaningExperience: ['cleaningExperience1', 'cleaningExperience2'],
    detergentCost: ['detergentCost'],
  },
  powder: {
    overallBio: ['powderOverallBio1', 'powderOverallBio2', 'powderOverallBio3'],
    cmr: ['powderCMR'],
    recommendedDosage: ['powderRecommendedDosage1', 'powderRecommendedDosage2'],
    lowestRecommendedWashTemp: ['lowestRecommendedWashTemp'],
    bioDegradability: ['powderDegradability1', 'powderDegradability2', 'powderDegradability3'],
    cleaningExperience: ['cleaningExperience1', 'cleaningExperience2'],
    detergentCost: ['detergentCost'],
  },
  unitDose: {
    overallBio: ['unitDoseOverallBio1', 'unitDoseOverallBio2', 'unitDoseOverallBio3', 'unitDoseOverallBio4'],
    cmr: ['unitDoseCMR'],
    recommendedDosage: ['unitDoseRecommendedDosage1', 'unitDoseRecommendedDosage2'],
    lowestRecommendedWashTemp: ['lowestRecommendedWashTemp'],
    bioDegradability: ['unitDoseDegradability1', 'unitDoseDegradability2', 'unitDoseDegradability3'],
    cleaningExperience: ['cleaningExperience1', 'cleaningExperience2'],
    detergentCost: ['detergentCost'],
  },
};

const getInformationTextByDetergentFormatAndFieldName = (
  format: DetergentFormat | undefined,
  fieldName: FieldName,
  t: TFunction
): string[] => {
  return format && fieldName
    ? translationKeysByFormatAndFieldName[format][fieldName].map((translationString) =>
        t(`formPage.informationText.${translationString}`)
      )
    : [];
};

export default getInformationTextByDetergentFormatAndFieldName;
