import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

type ImageProps = {
  src: string;
  alt: string;
};

type ImageWithTextOverLayProps = {
  id: string;
  headerText: string;
  bodyText: string;
  href: string;
  width?: string;
  height?: string;
  img: ImageProps;
};

type ImageWithTextOverLayContainer = {
  width?: string;
  height?: string;
};
const ImageWithTextOverLayContainer = styled.div<ImageWithTextOverLayContainer>`
  position: relative;
  display: flex;
  width: ${(props: ImageWithTextOverLayContainer): string => (props.width ? props.width : '100%')};
  height: ${(props: ImageWithTextOverLayContainer): string => (props.height ? props.height : '100%')};
`;

const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: #292929;
  opacity: 0.32;
`;

const TextOverLay = styled.div`
  position: absolute;
  bottom: 0;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.47);
  padding: 20px;
`;

const CategoryTextOverlay = styled.div`
  position: absolute;
  top: 0;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.47);
  padding: 20px;
`;

type ImageComponentProps = { src: string };
const Image = styled.div<ImageComponentProps>`
  background: url(${(props: ImageComponentProps): string => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

const ImageWithTextOverLay: FC<ImageWithTextOverLayProps> = ({ id, headerText, bodyText, img, width, height, href }) => {
  return (
    <ImageWithTextOverLayContainer id={id} width={width} height={height}>
      <a href={href}>
        <ImageOverlay>
          <Image src={img.src} title={img.alt} />
        </ImageOverlay>
        <BackgroundOverlay />
        <CategoryTextOverlay>
          <Typography variant="body2">Household care</Typography>
        </CategoryTextOverlay>
        <TextOverLay>
          <Typography variant="h2" style={{ marginBottom: 0 }}>
            {headerText}
          </Typography>
          <Typography variant="body1">{bodyText}</Typography>
        </TextOverLay>
      </a>
    </ImageWithTextOverLayContainer>
  );
};

export default ImageWithTextOverLay;
