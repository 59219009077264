import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonPrimary, ButtonSecondary } from '@novozymes/components';
import { Typography } from '@material-ui/core';
import Modal from './Modal';

type ClearFormModalProps = {
  onClose: () => void;
  onReset: () => void;
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ClearFormContainer = styled.div`
  padding: 20px;
`;

const ClearFormModal: FC<ClearFormModalProps> = ({ onClose, onReset }) => {
  const { t } = useTranslation();

  return (
    <Modal id="clear-form-modal" onClose={onClose}>
      <ClearFormContainer>
        <Typography variant="h1">{t('formPage.clearModal.header')}</Typography>
        <Typography variant="subtitle1">{t('formPage.clearModal.text')}</Typography>
        <ButtonContainer>
          <ButtonPrimary
            id="clear-form-yes-button"
            size="small"
            onClick={(): void => {
              onClose();
              onReset();
            }}
          >
            {t('common.yes')}
          </ButtonPrimary>
          <ButtonSecondary id="clear-form-no-button" size="small" onClick={onClose}>
            {t('common.no')}
          </ButtonSecondary>
        </ButtonContainer>
      </ClearFormContainer>
    </Modal>
  );
};

export default ClearFormModal;
