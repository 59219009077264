import React, { FC, useEffect, useState } from 'react';
import { Bar, BarChart, Label, Legend, ReferenceArea, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BioScore, Score } from '../types';
import { NOVOZYMES_GREEN, NOVOZYMES_BLUE } from '../colors';
import { ReactComponent as NovozymesLeaf } from '../assets/icons/nz_leaf2.svg';
import { ReactComponent as NovozymesMoney } from '../assets/icons/nz_moneybag.svg';
import { ReactComponent as NovozymesTshirt } from '../assets/icons/nz_t-shirt_whiteness1.svg';
import { ReactComponent as NovozymesWashingMachine } from '../assets/icons/nz_washing_machine_front_load_door_opened.svg';
import { ReactComponent as WasteWater } from '../assets/icons/nz_wastewater.svg';

type ResultGraphProps = {
  resultScore: BioScore;
  benchMark: BioScore;
};

type RechartsData = {
  name: string;
  bench: number;
  result: number;
};

const COUNTRIES_WITH_CHANGED_LEGEND = ['Argentina', 'Brazil', 'Chile', 'Colombia'];

const ChartWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
  padding: 20px;
  width: 100%;
`;

const ReferenceLabel = (props: any): JSX.Element | null => {
  const { fill, value, textAnchor, fontSize, viewBox, dy, dx, offset } = props;
  const x = viewBox.x + offset;
  const y = viewBox.y - offset;
  return (
    <text x={x} y={y} dy={dy} dx={dx} fill={fill} fontSize={fontSize || 10} textAnchor={textAnchor}>
      {value}
    </text>
  );
};

const ResultGraph: FC<ResultGraphProps> = ({ resultScore, benchMark }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<RechartsData[]>();
  const BIOLOGICAL_INGREDIENTS = t('resultsPage.graph.biologicalIngredients');
  const EFFICIENCY = t('resultsPage.graph.efficiency');
  const GENTLE_TO_PLANET = t('resultsPage.graph.gentleToPlanet');
  const CLEANING_EXPERIENCE = t('resultsPage.graph.cleaningExperience');
  const COMPETITIVE_COST = t('resultsPage.graph.competitiveCost');
  const YOUR_ESTIMATED_DETERGENT = t('resultsPage.graph.yourEstimatedDetergent');
  const TYPICAL_DETERGENT_IN_YOUR_COUNTRY = COUNTRIES_WITH_CHANGED_LEGEND.includes(resultScore.country)
    ? t('resultsPage.graph.typicalBioDetergentInYourCountry', { country: resultScore.country })
    : t('resultsPage.graph.typicalDetergentInYourCountry', { country: resultScore.country });
  const HUNDRED_PERCENT_BIO = t('resultsPage.graph.hundredPercentBio');
  const BETTER_FOR_PEOPLE_AND_PLANET = t('resultsPage.graph.betterForThePeopleAndPlanet');

  const CATEGORIES = [
    {
      name: BIOLOGICAL_INGREDIENTS,
      comparisonProperty: 'ingredientScore',
    },
    { name: EFFICIENCY, comparisonProperty: 'efficiencyScore' },
    { name: GENTLE_TO_PLANET, comparisonProperty: 'toxicityScore' },
    { name: CLEANING_EXPERIENCE, comparisonProperty: 'cleaningScore' },
    { name: COMPETITIVE_COST, comparisonProperty: 'costScore' },
  ];

  useEffect(() => {
    if (resultScore && benchMark) {
      setData(
        CATEGORIES.map(({ name, comparisonProperty }) => ({
          name,
          bench: benchMark[comparisonProperty as keyof Score],
          result: resultScore[comparisonProperty as keyof Score],
        }))
      );
    }
  }, [benchMark, resultScore]);

  const renderTick = (props: any): JSX.Element | null => {
    const { x, y, height, payload } = props;
    const svgHeight = height * 0.9;
    const newXPos = x - 22;
    let icon;
    switch (payload.value) {
      case BIOLOGICAL_INGREDIENTS:
        icon = <NovozymesLeaf x={newXPos} y={y} width={svgHeight} height={svgHeight} />;
        break;
      case EFFICIENCY:
        icon = <NovozymesWashingMachine x={newXPos} y={y} width={svgHeight} height={svgHeight} />;
        break;
      case GENTLE_TO_PLANET:
        icon = <WasteWater x={newXPos} y={y} width={svgHeight} height={svgHeight} />;
        break;
      case CLEANING_EXPERIENCE:
        icon = <NovozymesTshirt x={newXPos} y={y} width={svgHeight} height={svgHeight} />;
        break;
      case COMPETITIVE_COST:
        icon = <NovozymesMoney x={newXPos} y={y} width={svgHeight} height={svgHeight} />;
        break;
      default:
        icon = null;
    }
    return (
      <g fontSize="14px">
        {icon}
        <text x={x} y={y + height + 8} textAnchor="middle">
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ChartWrapper id="result-graph">
      <ResponsiveContainer width="99%" aspect={1.5}>
        <BarChart
          data={data}
          layout="horizontal"
          margin={{
            top: 30,
            left: -20,
            bottom: 20,
          }}
        >
          <ReferenceArea x1={BIOLOGICAL_INGREDIENTS} x2={CLEANING_EXPERIENCE} fill="#0f3e49" opacity={0.2}>
            <Label value={BETTER_FOR_PEOPLE_AND_PLANET} offset={20} position="insideTop" fontSize="14px" />
          </ReferenceArea>
          <ReferenceArea x1={CLEANING_EXPERIENCE} x2={COMPETITIVE_COST} fill="#c3da00" opacity={0.2}>
            <Label value={CLEANING_EXPERIENCE} offset={20} position="insideTop" fontSize="14px" />
          </ReferenceArea>
          <ReferenceArea x1={COMPETITIVE_COST} fill="#2d0028" opacity={0.2}>
            <Label value={COMPETITIVE_COST} offset={20} position="insideTop" fontSize="14px" />
          </ReferenceArea>
          <Bar name={TYPICAL_DETERGENT_IN_YOUR_COUNTRY} dataKey="bench" fill={NOVOZYMES_GREEN} />
          <Bar name={YOUR_ESTIMATED_DETERGENT} dataKey="result" fill={NOVOZYMES_BLUE} />
          <XAxis dataKey="name" tickLine={false} tick={renderTick} height={50} />
          <YAxis
            orientation="left"
            width={34}
            interval="preserveStart"
            ticks={[1, 2, 3, 4, 5]}
            scale="linear"
            domain={[0, 'dataMax + 2']}
          />
          <Legend verticalAlign="top" height={36} wrapperStyle={{ fontSize: 14 }} />
          <ReferenceLine
            y={5}
            stroke="#2d0028"
            strokeDasharray="3 3"
            label={<ReferenceLabel value={HUNDRED_PERCENT_BIO} position="top" fill="#2d0028" fontSize="14px" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default ResultGraph;
