import React, { FC, useCallback, useRef, useState } from 'react';
import { makeStyles, Typography, Box, Grid, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonSecondary } from '@novozymes/components';
import { pardotFormHandler, isIE } from '@novozymes/utils';
import getClientId from '../utils/GAWrapper';
import Modal from './Modal';
import { BioScore } from '../types';

type ContactFormProps = {
  onClose: () => void;
  country?: string;
  resultScore?: BioScore;
};

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const ContactFormContainer = styled.div`
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
`;

const MessagePadding = styled.div`
  margin-bottom: 20px;
`;

const PARDOT_FORM_ENDPOINT = 'https://nz.engage.novozymes.com/l/701243/2020-08-31/cqcbw';
const SOURCE_URL = window.location.href;
const GA_ID = getClientId();

const ContactFormModal: FC<ContactFormProps> = ({ onClose, country, resultScore }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onSubmit = useCallback(
    (e): void => {
      if (formRef.current === null || isIE()) {
        return;
      }
      e.preventDefault();
      pardotFormHandler(PARDOT_FORM_ENDPOINT, formRef.current, () => setHasSubmitted(true));
    },
    [formRef]
  );

  return (
    <Modal id="contact-form-modal" onClose={onClose}>
      <ContactFormContainer>
        <Typography variant="h1">{t('common.contactUs')}</Typography>
        {hasSubmitted ? (
          <div>
            <MessagePadding>
              <Typography variant="body1">{t('contactModal.thankYouForContacting')}</Typography>
            </MessagePadding>
            <ButtonPrimary onClick={onClose}>{t('common.close')}</ButtonPrimary>
          </div>
        ) : (
          <form method="POST" ref={formRef} onSubmit={onSubmit} action={isIE() ? PARDOT_FORM_ENDPOINT : ''}>
            <Typography variant="body1">{t('contactModal.text')}</Typography>
            <Grid container>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField className={classes.fullWidth} variant="outlined" required name="first name" label="First name" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField className={classes.fullWidth} variant="outlined" required name="last name" label="Last name" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField className={classes.fullWidth} variant="outlined" required name="email" label="E-Mail" type="mail" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField className={classes.fullWidth} variant="outlined" required name="company" label="Company" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField
                    className={classes.fullWidth}
                    variant="outlined"
                    multiline
                    rowsMax="10"
                    name="comment"
                    label="Comments"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <FormControlLabel
                  control={<Checkbox required value="True" name="i_accept_terms" />}
                  label={
                    <Typography variant="caption">
                      {t('contactModal.IAgreeWithNovozymes')}{' '}
                      <a href="https://www.novozymes.com/en/legal-notice" target="_blank" rel="noopener noreferrer">
                        {t('contactModal.termsAndConditions')}
                      </a>{' '}
                      {t('contactModal.andAcknowledgeNovozymes')}{' '}
                      <a target="_blank" rel="noopener noreferrer" href="https://new.novozymes.com/privacy">
                        {t('contactModal.privacyPolicy')}
                      </a>
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <FormControlLabel
                  control={<Checkbox value="True" name="i_opt_in" />}
                  label={<Typography variant="caption">{t('contactModal.yesIWouldLikeToReceiveUpdates')}</Typography>}
                />
              </Box>
            </Grid>
            <input type="hidden" name="country" value={country} />
            <input type="hidden" name="subject" value="Bio benchmark request" />
            <input type="hidden" name="ga_client_id" value={GA_ID} />
            <input type="hidden" name="pardot_source" value={SOURCE_URL} />
            {resultScore && <input type="hidden" name="pardot_calculator_values" value={JSON.stringify(resultScore)} />}
            <ButtonPrimary type="submit" id="contact-form-contact-button">
              {t('common.contactUs')}
            </ButtonPrimary>
            <ButtonSecondary id="contact-form-close-button" onClick={onClose}>
              {t('common.close')}
            </ButtonSecondary>
          </form>
        )}
      </ContactFormContainer>
    </Modal>
  );
};

export default ContactFormModal;
