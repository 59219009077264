import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-139084499-6';

export const initGa = (): void => {
  ReactGA.initialize(TRACKING_ID);
};

const getClientId = (): string => {
  try {
    const ga = ReactGA.ga();

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const tracker = ga.getByName('gtm1');
    if (tracker) {
      return tracker.get('dimension1');
    }
    return '000000';
  } catch (e) {
    // GA not initialized yet
    return '000000';
  }
};

export default getClientId;
