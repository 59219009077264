import React, { FC, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { getStatisticConsent, initializeCookieConsent, CookieHelper } from '@novozymes/utils';
import { initGa } from './utils/GAWrapper';
import CalculatePage from './pages/CalculatePage';

const initGTMandGA = (): boolean => {
  try {
    initGa();

    TagManager.initialize({
      gtmId: 'GTM-W52CMGT',
    });
    return true;
  } catch (error) {
    return false;
  }
};

const App: FC<{}> = () => {
  const [gtmGaInitialized, setGTMGAInitialized] = useState(false);
  useEffect(() => {
    // Cookie consent load
    initializeCookieConsent();

    if (!gtmGaInitialized) {
      CookieHelper.getInstance().listenCookieSet(getStatisticConsent, () => {
        setGTMGAInitialized(initGTMandGA());
      });
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <CalculatePage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
