import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components';
import FormComponent from '../components/FormComponent';
import ResultComponent from '../components/ResultsComponent';
import { BioScore } from '../types';

const CalculatePageContainer = styled.main`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100vh;
  max-width: 980px;
  width: 100%;
`;

const CalculatePage: FC<{}> = () => {
  const [resultScore, setResultScore] = useState<BioScore>();
  const [shouldDisplayResult, setShouldDisplayResult] = useState(false);

  const calculateResult = useCallback((values) => {
    setResultScore({
      country: values.country,
      format: values.format,
      ingredientScore: values.overallBio + values.cmr,
      efficiencyScore: Math.ceil((values.recommendedDosage + values.lowestRecommendedWashTemp) / 2),
      toxicityScore: values.bioDegradability,
      cleaningScore: values.cleaningExperience,
      costScore: values.detergentCost,
    });
    setShouldDisplayResult(true);
    window.scrollTo(0, 0);
  }, []);

  return (
    <CalculatePageContainer className="calculatePage">
      {shouldDisplayResult && resultScore ? (
        <ResultComponent resultScore={resultScore} onBackPress={(): void => setShouldDisplayResult(false)} />
      ) : (
        <FormComponent calculateResult={calculateResult} />
      )}
    </CalculatePageContainer>
  );
};

export default CalculatePage;
