import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSecondary } from '@novozymes/components';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Modal from './Modal';

type GoBackModalProps = {
  onClose: () => void;
  onReset: () => void;
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const GoBackContainer = styled.div`
  padding: 20px;
`;

const GoBackModal: FC<GoBackModalProps> = ({ onClose, onReset }) => {
  const { t } = useTranslation();

  return (
    <Modal id="go-back-modal" onClose={onClose}>
      <GoBackContainer>
        <Typography variant="h1">{t('resultsPage.goBackModal.header')}</Typography>
        <Typography variant="subtitle1">{t('resultsPage.goBackModal.text')}</Typography>
        <ButtonContainer>
          <ButtonSecondary
            id="go-back-yes-button"
            size="small"
            onClick={(): void => {
              onClose();
              onReset();
            }}
          >
            {t('common.yes')}
          </ButtonSecondary>
          <ButtonSecondary id="go-back-no-button" size="small" onClick={onClose}>
            {t('common.no')}
          </ButtonSecondary>
        </ButtonContainer>
      </GoBackContainer>
    </Modal>
  );
};
export default GoBackModal;
