import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { ButtonPrimary, ButtonSecondary } from '@novozymes/components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Typography } from '@material-ui/core';
import { DETERGENT_FORMAT, DetergentFormat } from '../types';
import BENCHMARKS_BY_COUNTRY from '../assets/benchmarksByCountry.json';
import COUNTRIES from '../assets/salesforceCountries';
import ContactFormModal from './ContactFormModal';
import DetergentForm from './DetergentForm';

const PreviousButtonContainer = styled.div`
  align-self: flex-start;
  margin-bottom: 20px;
`;

const validationSchema = Yup.object().shape({
  overallBio: Yup.number().required('Overall bio is a required field'),
  cmr: Yup.number().required('CMR content is a required field'),
  recommendedDosage: Yup.number().required('Recommended dosage is a required field'),
  lowestRecommendedWashTemp: Yup.number().required('Lowest recommended wash temperature is a required field'),
  bioDegradability: Yup.number().required('Biodegradability is a required field'),
  cleaningExperience: Yup.number().required('Cleaning experience is a required field'),
  detergentCost: Yup.number().required('Detergent cost is a required field'),
});

const initialValues = {
  overallBio: undefined,
  cmr: undefined,
  recommendedDosage: undefined,
  lowestRecommendedWashTemp: undefined,
  bioDegradability: undefined,
  cleaningExperience: undefined,
  detergentCost: undefined,
};

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const NotSupported = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 1rem;
`;

const Previous = styled.a`
  text-decoration: none;
`;

const RadioInput = styled.input`
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
`;

const QuestionHeaderText = styled.h2<{ hasError?: boolean }>`
  ${({ hasError }): string => (hasError ? 'color: red' : '')}
`;

type FormComponentProps = {
  calculateResult: (values: any) => void;
};

const FormComponent: FC<FormComponentProps> = ({ calculateResult }) => {
  const { t } = useTranslation();
  const [shouldShowContactForm, setShouldShowContactForm] = useState<boolean>();
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedFormat, setSelectedFormat] = useState<DetergentFormat>();
  const [countryBenchmarkDoesNotExist, setCountryBenchmarkDoesNotExist] = useState(false);
  const [formatBenchmarkDoesNotExist, setFormatBenchmarkDoesNotExist] = useState(false);

  useEffect(() => {
    if (selectedCountry) {
      const countryBenchMarks = BENCHMARKS_BY_COUNTRY[selectedCountry as keyof typeof BENCHMARKS_BY_COUNTRY];
      setCountryBenchmarkDoesNotExist(!countryBenchMarks);
      if (countryBenchMarks && selectedFormat) {
        setFormatBenchmarkDoesNotExist(!countryBenchMarks[selectedFormat as keyof typeof countryBenchMarks]);
      }
    }
  }, [selectedFormat, selectedCountry]);
  const notSupportedCountryAndFormatCombo = countryBenchmarkDoesNotExist || formatBenchmarkDoesNotExist;

  return (
    <div>
      <PreviousButtonContainer>
        <Previous href="https://new.novozymes.com/household-care/biological-detergent/benchmark-tool">
          <ButtonSecondary id="formpage-previous-button" size="small">
            <ChevronLeftIcon />
            {t('common.previousPage')}
          </ButtonSecondary>
        </Previous>
      </PreviousButtonContainer>
      {shouldShowContactForm && (
        <ContactFormModal country={selectedCountry} onClose={(): void => setShouldShowContactForm(false)} />
      )}
      <Typography variant="h1">{t('formPage.formHeader')}</Typography>
      <Typography variant="body1">{t('formPage.introduction')}</Typography>
      <div id="country-selection">
        <QuestionHeaderText>{t('formPage.country')}</QuestionHeaderText>
        <Select
          aria-label="select your country"
          styles={{
            container: (provided): Record<string, any> => ({
              ...provided,
              fontSize: '16px',
            }),
            placeholder: (provided): Record<string, any> => ({
              ...provided,
              color: '#767676',
              opacity: 1,
            }),
          }}
          options={COUNTRIES.map((country) => ({ value: t(`countries.${country}`), label: t(`countries.${country}`) }))}
          onChange={(option: any): void => {
            setSelectedCountry(option.value);
          }}
        />
      </div>
      <div id="format-selection">
        <QuestionHeaderText>{t('formPage.detergentFormat')}</QuestionHeaderText>
        <div>
          {[
            { label: t('formPage.liquid'), value: DETERGENT_FORMAT.LIQUID },
            { label: t('formPage.powder'), value: DETERGENT_FORMAT.POWDER },
            {
              label: t('formPage.unitDose'),
              value: DETERGENT_FORMAT.LIQUID_UNIT_DOSE,
            },
          ].map((format) => (
            <RadioContainer key={format.label}>
              <RadioInput
                type="radio"
                id={format.label}
                name="format"
                value={format.value}
                checked={selectedFormat === format.value}
                onChange={(event): void => setSelectedFormat(event.target.value as DetergentFormat)}
              />
              <Label htmlFor={format.label}>{format.label}</Label>
              <br />
            </RadioContainer>
          ))}
        </div>
      </div>
      {notSupportedCountryAndFormatCombo ? (
        <>
          <NotSupported>
            <Typography variant="body1">
              {countryBenchmarkDoesNotExist ? t('formPage.noBenchmarksForCountry') : t('formPage.contactUsBody')}
            </Typography>
          </NotSupported>
          <ButtonPrimary size="small" onClick={(): void => setShouldShowContactForm(true)}>
            {t('common.contactUs')}
          </ButtonPrimary>
        </>
      ) : (
        selectedFormat &&
        selectedCountry && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values): void => calculateResult({ ...values, country: selectedCountry, format: selectedFormat })}
          >
            <DetergentForm format={selectedFormat} />
          </Formik>
        )
      )}
    </div>
  );
};

export default FormComponent;
