import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSecondary } from '@novozymes/components';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Modal from './Modal';

export type InformationModalContent = {
  headerText: string;
  bodyText: string[];
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const InformationContainer = styled.div`
  padding: 20px;
`;

type InformationModalProps = InformationModalContent & {
  onClose: () => void;
};

const InformationModal: FC<InformationModalProps> = ({ onClose, headerText, bodyText }) => {
  const { t } = useTranslation();
  return (
    <Modal id={`info-modal-${headerText}`} onClose={onClose}>
      <InformationContainer>
        <Typography variant="h1">{headerText}</Typography>
        <ul>
          {bodyText.map((text) => (
            <li>
              <Typography variant="subtitle1">{text}</Typography>
            </li>
          ))}
        </ul>
        <ButtonContainer>
          <ButtonSecondary size="small" onClick={onClose}>
            {t('common.close')}
          </ButtonSecondary>
        </ButtonContainer>
      </InformationContainer>
    </Modal>
  );
};

export default InformationModal;
