import { TFunction } from 'i18next';
import { DetergentFormat, DETERGENT_FORMAT } from '../types';

type Option = { label: string; value: number };

type Question = {
  fieldName: string;
  fieldType?: string;
  question: string;
  options: Option[];
};

export const getDosageOptionsByFormat = (format: DetergentFormat, t: TFunction): Option[] => {
  if (format === DETERGENT_FORMAT.LIQUID) {
    return [
      { label: t('formPage.recommendedDosage.liquid.lessThan25mL'), value: 4 },
      { label: t('formPage.recommendedDosage.liquid.25to54mL'), value: 3 },
      { label: t('formPage.recommendedDosage.liquid.55mL'), value: 2 },
      { label: t('formPage.recommendedDosage.liquid.moreThan55mL'), value: 1 },
    ];
  }
  if (format === DETERGENT_FORMAT.POWDER) {
    return [
      { label: t('formPage.recommendedDosage.powder.lessThan31g'), value: 4 },
      { label: t('formPage.recommendedDosage.powder.31to45g'), value: 3 },
      { label: t('formPage.recommendedDosage.powder.45to75g'), value: 2 },
      { label: t('formPage.recommendedDosage.powder.moreThan75g'), value: 1 },
    ];
  }
  if (format === DETERGENT_FORMAT.LIQUID_UNIT_DOSE) {
    return [
      { label: t('formPage.recommendedDosage.liquidUnitDose.lessThan15mL'), value: 5 },
      { label: t('formPage.recommendedDosage.liquidUnitDose.15to20mL'), value: 4 },
      { label: t('formPage.recommendedDosage.liquidUnitDose.21to25mL'), value: 3 },
      { label: t('formPage.recommendedDosage.liquidUnitDose.moreThan25mL'), value: 2 },
    ];
  }
  return [];
};

const getLowestRecommendedWashTemp = (format: DetergentFormat, t: TFunction): Option[] => {
  if (format === DETERGENT_FORMAT.LIQUID || format === DETERGENT_FORMAT.LIQUID_UNIT_DOSE) {
    return [
      { label: t('formPage.lowestRecommendedWashTemp.coldWater'), value: 4 },
      { label: t('formPage.lowestRecommendedWashTemp.20degCelsius'), value: 3 },
      { label: t('formPage.lowestRecommendedWashTemp.30degCelsius'), value: 2 },
      { label: t('formPage.lowestRecommendedWashTemp.40degCelsius'), value: 1 },
    ];
  }
  if (format === DETERGENT_FORMAT.POWDER) {
    return [
      { label: t('formPage.lowestRecommendedWashTemp.coldWater'), value: 5 },
      { label: t('formPage.lowestRecommendedWashTemp.20degCelsius'), value: 4 },
      { label: t('formPage.lowestRecommendedWashTemp.30degCelsius'), value: 3 },
      { label: t('formPage.lowestRecommendedWashTemp.40degCelsius'), value: 2 },
    ];
  }
  return [];
};

const getFormQuestionsByDetergentFormat = (format: DetergentFormat | undefined, t: TFunction): Question[] => {
  return typeof format !== 'undefined'
    ? [
        {
          fieldName: 'overallBio',
          question: t('formPage.overallBio.question'),
          options: [
            { label: t('formPage.overallBio.lessThan50'), value: 2 },
            { label: t('formPage.overallBio.50to95'), value: 3 },
            { label: t('formPage.overallBio.96to99'), value: 4 },
            { label: t('formPage.overallBio.100'), value: 5 },
          ],
        },
        {
          fieldName: 'cmr',
          question: t('formPage.cmr.question'),
          options: [
            { label: t('formPage.cmr.yes'), value: -1 },
            { label: t('formPage.cmr.no'), value: 0 },
          ],
        },
        {
          fieldName: 'recommendedDosage',
          question: t('formPage.recommendedDosage.question'),
          options: getDosageOptionsByFormat(format, t),
        },
        {
          fieldName: 'lowestRecommendedWashTemp',
          question: t('formPage.lowestRecommendedWashTemp.question'),
          options: getLowestRecommendedWashTemp(format, t),
        },
        {
          fieldName: 'bioDegradability',
          fieldType: 'select',
          question: t('formPage.bioDegradability.question'),
          options: [
            { label: t('formPage.bioDegradability.manyNonBioDegradable'), value: 1 },
            { label: t('formPage.bioDegradability.fewNonBioDegradable'), value: 2 },
            { label: t('formPage.bioDegradability.mostBioDegradableAerobically'), value: 3 },
            { label: t('formPage.bioDegradability.mostBioDegradableAerobicallyAndAnaerobically'), value: 4 },
            { label: t('formPage.bioDegradability.allIngredientsBiodegradable'), value: 5 },
          ],
        },
        {
          fieldName: 'cleaningExperience',
          question: t('formPage.cleaningExperience.question'),
          options: [
            { label: t('formPage.cleaningExperience.belowAverage'), value: 2 },
            { label: t('formPage.cleaningExperience.average'), value: 3 },
            { label: t('formPage.cleaningExperience.aboveAverage'), value: 4 },
            { label: t('formPage.cleaningExperience.outStanding'), value: 5 },
          ],
        },
        {
          fieldName: 'detergentCost',
          question: t('formPage.detergentCost.question'),
          options: [
            { label: t('formPage.detergentCost.lessExpensive'), value: 5 },
            { label: t('formPage.detergentCost.onPar'), value: 3 },
            { label: t('formPage.detergentCost.moreExpensive'), value: 1 },
          ],
        },
      ]
    : [];
};

export default getFormQuestionsByDetergentFormat;
