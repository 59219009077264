/* eslint-disable max-len */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ImageWithTextOverLay from './ImageWithTextOverlay';

const EngageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const EngageSection = styled.section`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
`;

type EngageColumnProps = { padding?: string; mobileHeight?: string };
const EngageColumn = styled.div<EngageColumnProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 10px);
  height: 100%;

  @media (max-width: 767px) {
    ${({ mobileHeight }: EngageColumnProps): string => (mobileHeight ? `height: ${mobileHeight};` : '')}
    width: 100%;
    margin-bottom: 20px;
  }
`;

const EngageComponent: FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <EngageContainer>
      <TextWrapper>
        <Typography variant="h1">{t('resultsPage.engage.header')}</Typography>
      </TextWrapper>
      <TextWrapper>
        <Typography variant="body1">{t('resultsPage.engage.subtitle')}</Typography>
      </TextWrapper>
      <EngageSection>
        <EngageColumn>
          <ImageWithTextOverLay
            id="engage-white-paper"
            href="https://new.novozymes.com/industry/whitepaper"
            height="100%"
            headerText={t('resultsPage.engage.greenerDetergents')}
            bodyText={t('resultsPage.engage.readWhitePaper')}
            img={{
              src:
                'https://new.novozymes.com/sites/default/files/styles/block_grid/public/field_media_image/Download%20whitepaper%20684%202.jpg?itok=7qxHRP5X',
              alt: 'How to enable greener and high performing detergents?',
            }}
          />
        </EngageColumn>
        <EngageColumn mobileHeight="200%">
          <ImageWithTextOverLay
            id="engage-co2"
            height="calc(50% - 10px)"
            href="https://new.novozymes.com/industry/how-much-co2-could-you-be-saving"
            headerText={t('resultsPage.engage.howMuchCO2')}
            bodyText={t('resultsPage.engage.tryCalculator')}
            img={{
              src:
                'https://new.novozymes.com/sites/default/files/styles/block_grid/public/field_media_image/Calculator%20clip%20no%20re.gif?itok=BunextRH',
              alt: 'How to enable greener and high performing detergents?',
            }}
          />
          <ImageWithTextOverLay
            id="engage-trends"
            height="calc(50% - 10px)"
            href="https://new.novozymes.com/industry/why-detergent-industry-cant-get-green-fast-enough"
            headerText={t('resultsPage.engage.consumerTrends')}
            bodyText={t('resultsPage.engage.readTrendArticle')}
            img={{
              src:
                'https://new.novozymes.com/sites/default/files/styles/block_grid/public/field_media_image/15%20684%20dark.jpg?itok=f-f88Rbc',
              alt: 'How to enable greener and high performing detergents?',
            }}
          />
        </EngageColumn>
      </EngageSection>
    </EngageContainer>
  );
};

export default EngageComponent;
