import React, { FC, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';

type ModalProps = {
  id: string;
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalMain = styled.section`
  position: relative;
  background: white;
  border-radius: 5px;
  max-width: 400px;
`;

const IconContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
`;

const modalRoot = document.getElementById('modal-root');

const Modal: FC<ModalProps> = ({ id, children, onClose }) => {
  const closeModal = useCallback(
    (event) => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );
  return modalRoot
    ? createPortal(
        <Overlay id="modal-overlay" role="presentation" onClick={closeModal}>
          <ModalMain id={id} role="presentation" onClick={(event): void => event.stopPropagation()}>
            <IconContainer id="modal-close-icon">
              <ClearIcon onClick={onClose} />
            </IconContainer>
            {children}
          </ModalMain>
        </Overlay>,
        modalRoot
      )
    : null;
};

export default Modal;
